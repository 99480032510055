.infoPage {
    margin-top: 120px;
    margin-left: 3%;
    margin-right: 3%;
    color: #303030;
}

.title {
    color: #303030;
    font-size: 2em;
    font-weight: 700px;
    padding-bottom: 0;
    margin-bottom: 0;
}

.oneLine {
    border: #E1E1E1 1px solid;
}

.entryLine {
    margin-top: 40px;
}