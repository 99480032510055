.courseAverageContainer {
    display: inline-block;
    text-align:center;
    font-family: 'Roboto';
    background-color: #E46060;
    border-radius: 20px;
    color: #f1eeee;
    width: 116px;
    height: 116px;
    border: #FBFBFB solid 1px;
    margin-left: 8px;
}

.courseAverage {
    font-size: 40px;
    line-height: 0;
}

.courseAverageContainer h2 {
    margin-top: 12px;
    font-size: 20px;
    margin-bottom: 26px;
}