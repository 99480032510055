.createProfessorPopupContainer {
    position: absolute;
    width: 46rem;
    height: 30rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.createProfessorPopup {
    background-color: #FBFBFB;
    width: 50%;
    padding: 1rem;
    padding-top: 0.2rem;
    border-radius: 10px;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
    z-index: 2200;
}

.overlay {
    width: 100%;
    height: 100%;
    text-align: center;
    position: fixed;
    margin-left: 0;
    background-color: rgba(0, 0, 0, 0.25);
    z-index: 2100;
    top:0;
}

.inputProfessorDetails {
    text-align: left;
    padding-top: 0;
    border: 1px #B5B5B5;
    margin-left: 3%;
    margin-right: 10%;
}

.inputProfessorDetails h2 {
    margin: 0;
    padding-bottom: 0.5rem;
    font-size: 20px;
    color: #303030;
}

.inputProfessorDetails input {
    color: #303030;
    font-size: 16px;
    font-weight: 400;
    width: 100%;
    height: 65%;
    border-radius: 5px;
    font-size: 16px;
    border: 1px #B5B5B5;
    padding-left: 0.4rem;
    margin-bottom: 0.5rem;
}

.inputProfessorDetails input[type="text"] {
    padding: 0.5rem;
    border: 1px solid #B5B5B5;
}

.inputProfessorDetails input::placeholder {
    font-weight: 400;
    font-size: 16px;
    color: #B5B5B5;
}

.inputProfessorDetails input:focus {
    outline: none;
}

.cancelButton2 {
    color: #7e7b7b;
    border: 0;
    border-radius: 50%;
    font-size: 16px;
    font-weight: 500;
    margin-left: 90%;
    background-color: rgb(248, 248, 248);
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.cancelButton2:hover {
    box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.04);
    color: rgb(248, 248, 248);
    background-color: #3D3D3D;
    transition: background-color 0.5s ease;
}

.submitButton2 {
    color: #FBFBFB;
    background-color: #303030;
    width: 96%;
    height: 2.5rem;
    border: 0;
    border-radius: 10px;
    font-size: 20px;
    font-weight: 500;
    box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.04);
    margin-top: 5%;
}

.submitButton2:hover {
    cursor: pointer;
    background-color: #303030;
    transition: background-color 0.3s ease;
}