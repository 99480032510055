.sectionContainer {
  overflow-x: scroll;
  padding-left: 1.5rem;
}

.gridContainer {
  padding-bottom: 3em;
  display: flex;
  white-space: nowrap;
  scroll-behavior: smooth; /* For smoother scrolling */
  -webkit-overflow-scrolling: touch; /* For better mobile scrolling */
}

@keyframes scroll-left {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }

.gridItem {
    width: 24rem;
    min-width: 24rem;
    height: auto;
    border: 0px none #ccc;
    margin-right: 3rem;
    margin-left: 2%;
}

.gridItem:last-child {
    margin-right: 0;
}

.sectionTitle span {
    margin: 0;
    color: #303030;
    font-size: 25px;
    font-weight: 700;
    margin-left: 3%;
    padding-top: 1rem;
    width: fit-content;
}

.sectionTitle {
    display: flex;
    flex-direction: column;
  }
  
  .title-tooltip {
    display: inline-block;
  }

.courseName {
    margin: 0;
    padding: 5px;
    padding-left: 20px;
    padding-right: 17px;
    white-space: nowrap;
}

.homeImageContainer {
  background: linear-gradient(to right, #2E8BFF, #3A9FFC, #66B5FF);
  padding-top: 12px;
  padding-bottom: 4px;
  position: relative;
  overflow: visible;
}

.homeCourseSections {
  margin-top: 35px;
}

.homeImageContainer::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  height: 65px;
  bottom: 0;
  background-color: white;
}

.homeImageContainer > * {
  position: relative;
  z-index: 1;
}

.homeTitle {
  position: absolute;
  font-size: 42px;
  margin-top: 6%;
  width: 100%;
}

.homeTitle h1 {
  text-align: center;
  color: white;
  margin: 0;
  font-weight: 900;
  margin-bottom: 1rem;
}

.homeTitle h2 {
  margin: 0;
  font-weight: 300;
}

@media (max-width: 1400px) {
  .homeTitle {
      font-size: 24px;
  }
}

@media (max-width: 1200px) {
  .homeTitle {
    display: none;
  }
}
