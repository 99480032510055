@font-face {
    font-family: 'uni-sans-heavy';
    src: url('../public/fonts/uni-sans.heavy-caps.otf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'uni-sans-light';
    src: url('../public/fonts/uni-sans.thin-caps.otf');
}

@font-face {
    font-family: 'lora';
    src: url('../public/fonts/Lora.ttf');
}

@font-face {
    font-family: 'roboto-medium';
    src: url('../public/fonts/Roboto-Medium.ttf');
}

body {
    font-family: "Roboto";
    background-color: white;
}

.main-container {
    margin: 0;
}

::-webkit-scrollbar {
    height: 5px;
    width: 5px;
}

::-webkit-scrollbar-track {
    background: rgb(251, 251, 251);
}

::-webkit-scrollbar-thumb {
    border-radius: 50px;
    border: 3px solid rgb(222, 222, 222);
}
