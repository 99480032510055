.coursePageTitle {
    justify-content: space-between;
    margin-top: 120px;
}

.overallGrade {
    position: relative;
    align-items: center;
    margin-top: 60px;
    display: flex;
    justify-content: space-between;
}

.courseRatings {
    margin-right: 3%;
    display: flex;
}

.codeContainer {
    font-family: "Roboto";
    font-weight: 500;
}

.code {
    margin: 0;
    color: #303030;
    font-size: 100px;
    font-weight: 700;
}

.name {
    margin-top: 0;
    color: #A2ADB9;
    font-weight: 400;
}

.courseInfo {
    float: left;
    position: relative;
    margin-left: 3%;
    font-size: 32px;
}

.grade {
    display: inline-block;
    text-align: center;
    margin-left: 8px;
}

.experienceGradeContainer, 
.difficultyGradeContainer, 
.workloadGradeContainer {
    border: #FBFBFB solid 1px;
    border-radius: 30px;
    font-size: 12px;
    width: 160px;
    color: #f1eeee;
    font-family: 'Roboto';
    padding: 8px;
    vertical-align: middle;
}

.experienceGradeContainer {
    background-color: #7D4BBD;
}

.difficultyGradeContainer {
    background-color: #606DE4;
}

.workloadGradeContainer {
    background-color: #CE599F;
}

.courseAverageContainer {
    display: flex;
}

.experienceGradeContainer h3,
.difficultyGradeContainer h3,
.workloadGradeContainer h3,
.experienceGradeContainer h2,
.difficultyGradeContainer h2,
.workloadGradeContainer h2 {
    display: inline-block;
    margin: 0;
}

.experienceGradeContainer h2,
.difficultyGradeContainer h2,
.workloadGradeContainer h2 {
    display: inline-block;
    align-items: center;
    margin-left: 10px;
}

.difficultyGradeContainer h2 {
    margin-left: 18px;
}

.workloadGradeContainer h2 {
    margin-left: 18px;
}

.workloadText {
    font-size: 10px;
}

.workloadGradeContainer h3 {
    margin-left: 12px;
}

.requirementInfo {
    border-radius: 10px;
    padding: 10px;
    margin-top: 8px;
    margin-right: 3%;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
    background-color: #fcfbfb;
}

.requirementInfo p {
    margin: 8px 0; /* Adjust the margin between paragraphs */
}

.additionalInfo {
    margin-left: 3%;
    font-size: 16px;
    color: #303030;
}

.additionalInfo h2 {
    margin: 0;
    color: #7d7979;
}
