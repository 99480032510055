.reviewSection {
    font-family: "Roboto";
    margin-right: 3%;
    margin-left: 3%;
    margin-top: 64px;
}

.reviewSection a {
    color: #000000;
    font-weight: 500;
}

.review2 {
    display: flex;
    justify-content: space-between;
    align-items:center;
    line-height:2px;
}

.reviewSectionHeader {
    color: #303030;
    font-size: 20px;
}

.reviewSectionHeader h1 {
    font-weight: 700;
}

.blackLine {
    border: #E1E1E1 1px solid;
    margin-top: 8px;
}

.leaveReviewButton:hover {
    cursor: pointer;
    transform: scale(1.05);
}

.emptyMessage {
    color: #303030;
}

.reviewCard {
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
    background-color: #fbfbfb;
    border-radius: 15px;
    margin-top: 20px;
    font-family: "Roboto";
    padding: 5px;
    margin-bottom:20px;
}

.firstLine {
    margin-top:10px;
    display: flex;
    justify-content: space-between;
    margin-bottom:0;
}

.secondLine {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
}

.reviewer,
.professorReviewed {
    font-weight: bold;
    font-family: "Roboto";
    padding-bottom: 0;
    margin-left: 1%;
    margin-right: 2%;
}

.reviewer {
    color: #3D3D3D;
    font-size: 24px;
}

.professorReviewed {
    color: #7d7979;
    font-size: 20px;
}

.professorReviewed p {
    margin: 0;
    padding: 0;
}

.comment {
    font-size: 20px;
    margin-left: 1%;
    margin-right: 2%;
    display: flex;
    margin-top: 0;
    color:#303030;
}

.reviewer, 
.professorReviewed,
.leftSideFirstLine,
.userExperienceContainer, 
.userDifficultyContainer, 
.userWorkloadContainer,
.uploadedTimeContainer,
.profile {
    display: inline-flex;
    text-wrap:nowrap;
    margin-top: 0;
    margin-bottom: 0;
}

.userExperienceContainer, 
.userDifficultyContainer, 
.userWorkloadContainer,
.uploadedTimeContainer,
.profile {
    margin-right: 10px;
    color: white;
    border-radius: 30px;
    height:30px;
    font-family: "Roboto";
    align-items: center;
    justify-content: center;
    background-color: #fbfbfb;
    font-size: 16px;
    padding-right: 8px;
    padding-left: 8px;
}

.userExperienceContainer {
    color: #7D4BBD;
    border: #7D4BBD solid 1px;
}

.userDifficultyContainer {
    color: #606DE4;
    border: #606DE4 solid 1px;
}

.userWorkloadContainer {
    color: #CE599F;
    border: #CE599F solid 1px;
}

.uploadedTimeContainer {
    color: #303030;
    justify-content: flex-end;
}

.profile {
    color: #737373;
    justify-content: flex-end;
}

.reviewerProfile {
    color: white;
    background: linear-gradient(145deg, #3A9FFC, #2A8FDC);
    margin: 4px;
    margin-bottom: 6px;
    padding-right: 6px;
    font-size: 16px;
    border-radius: 15px;
    font-weight: 500;
    border: 1px solid #2A8FDC;
}