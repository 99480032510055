.overlayWriteReviewContainer {
    width: 100%;
    height: 100%;
    text-align: center;
    position: fixed;
    margin-left: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 2100;
    top:0;
}

.writeReviewContainer {
    z-index: 2100;
    margin-top: 112px;
    display: inline-block;
    width: 43%;
    min-width: 800px;
    height: auto;
    background-color: rgb(248, 248, 248);
    border-radius: 15px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
}

.writeReviewContainerElements {
    padding: 2rem;
    padding-bottom: 1.5rem;
    padding-top:0;
}

.writeReviewContainerElements h1 {
    margin: 0;
    margin-bottom: 0.5rem;
    text-align: left;
    color: #303030;
}

.horizontalLine {
    margin-bottom: 1rem;
    height: 2px;
    background-color: #E1E1E1;
}

.fieldContainer {
    margin-bottom: 1.5rem;
    display: flex;
    justify-content: space-between;
}

.inputFieldContainer {
    text-align: left;
    display: flex;
}

.inputField {
    float: left;
    text-align: left;
    padding-top: 0;
    border: 1px #B5B5B5;
}

.inputFieldHeader {
    display: flex;
    align-items: center;
}

.inputField h2 {
    margin: 0;
    padding-bottom: 0.3em;
    font-size: 20px;
    color: #7d7979;
}

.inputField input {
    color: #5E5E5E;
    font-size: 16px;
    font-family: "Roboto";
    font-weight: 400;
    width: 80%;
    height: 65%;
    border-radius: 5px;
    font-size: 16px;
    border: 1px #B5B5B5;
    padding-left: 0.4rem;
}

.inputField input[type="text"] {
    padding: 0.5rem;
    border: 1px solid #B5B5B5;
}

.inputField input::placeholder {
    font-family: "Roboto";
    font-weight: 400;
    font-size: 16px;
    color: #B5B5B5;
}

.inputField input:focus {
    outline: none;
}

.toggleSwitch {
    float: right;
    position: relative;
    width: 60px;
    height: 30px;
}
  
input[type='checkbox'] {
    display: none;
}
  
input[type='checkbox']:checked + .slider {
    background-color: #7DDA7B;
}
  
input[type='checkbox']:checked + .slider:before {
    transform: translateX(26px);
}

.ratingsContainer {
    margin-bottom: 0.5rem;
    text-align: left;
}

.ratingsContainer h2 {
    color: #7d7979;
    margin: 0;
    margin-bottom: 0.5rem;
    font-size: 20px;
}

.ratingInputsContainer {
    display: flex;
    flex-wrap: wrap;
}

.ratingField {
    flex: 0 1 auto;
    max-width: 50%;
    margin-right: 2rem;
    margin-bottom: 1rem;
    align-items: center;
    display: flex;
    border-radius: 15px;
    height: 3rem;
    padding-left: 0.5rem;
    box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.04);
}

.ratingField h2 {
    font-size: 16px;
    margin: 0;
    color: #FBFBFB;
    padding: 1rem;
    padding-right: 1.3rem;
    letter-spacing: 1px;
    font-weight: bold;
}

.ratingField input {
    color: #FFFFFF;
    border-radius: 0px 15px 15px 0px;
    text-align: center;
    font-size: 16px;
    font-family: "Roboto";
    font-weight: 400;
    margin: 0;
    padding: 0;
    padding-left: 0.5rem;
    padding-right: 0.6rem;
    width: 2.5rem;
    height: 100%;
    border: 0;
}

.ratingField input[type="text"] {
    font-family: "Roboto";
    font-weight: 400;
}

.ratingField input::placeholder {
    font-family: "Roboto";
    font-weight: 400;
    font-size: 16px;
    color: #FFFFFF;
}

.ratingField input:focus {
    outline: none;
}

.ratingField input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.inputContent {
    margin-bottom: 1.5rem;
    border-radius: 10px;
    text-align: left;
    float: left;
    height: 40%;
    width: 100%;
    border: 1px solid #B5B5B5;
}

.inputContent textarea {
    box-sizing: border-box;
    padding: 0;
    padding-left: 0.5rem;
    font-size: 16px;
    font-family: "Roboto";
    color: #5E5E5E;
    font-weight: 400;
    border-radius: 10px;
    border: 0;
    height: 8rem;
    width: 100%;
    background-color: #F8F8F8;
    padding-top: 0.5rem;
    resize: none;
}

.inputContent textarea::placeholder {
    font-family: "Roboto";
    font-weight: 400;
    font-size: 16px;
    color: #B5B5B5;
}

.inputContent textarea:focus {
    outline: none;
}

.actionButtonsContainer {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.cancelButton {
    color: #7e7b7b;
    margin-left: 95%;
    margin-top: 1%;
}

.cancelButton:hover {
    color: rgb(233, 122, 122);
    transition: color 0.5s ease;
    cursor: pointer;
}

.submitButton {
    color: #FBFBFB;
    background-color: #303030;
    width: 100%;
    height: 2.5rem;
    border: 0;
    border-radius: 10px;
    font-size: 20px;
    font-family: "Roboto";
    font-weight: 500;
    box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.04);
}

.submitButton:hover {
    cursor: pointer;
    background-color: #5e5e5e;
    transition: background-color 0.3s ease;
}

.searchResults {
    text-align: left;
    position: relative;
    overflow: scroll;
    display: block;
    background-color: #FFFFFF;
    border-radius: 0px 0px 10px 10px;
    color: #303030;
    border: 1px solid #B5B5B5;
    max-height: 5rem;
    width: calc(100% - 1.5rem); 
}

.searchResultsEmpty {
    display: none;
}

.courseSearchResult {
    padding: 0.5rem;
}

.courseSearchResult:hover,
.courseSearchResult.selected {
    background-color:#303030;
    color: white;
}

.professorSearchResult {
    padding: 0.5rem;
}

.professorSearchResult:hover,
.professorSearchResult.selected {
    background-color:#303030;
    color: white;
}

.professorListContainer {
    display: flex;
    align-items: baseline;
    margin-bottom: auto;
    margin-top: 36px;
}

.professorListContainer .professor {
    margin: 0;
    margin-right: 0.5rem;
    padding: 0.4rem;
    background-color: #bbbbbb;
    border-radius: 10px;
    text-align: center;
    color: #fbfbfbfb;
    font-weight: 300;
    margin-top: auto;
}

.professorListContainer .professor:hover {
    background-color: rgb(231, 117, 117);
    cursor: pointer;
    font-weight: bold;
}

.professorListContainer p {
    margin: 0;
    font-size: 12px;
}
 
.toastMessage {
    font-family: "Roboto";
}

.ratingField input::placeholder {
    color: #e5dddd;
} 

.recordButton {
    display: flex;
}