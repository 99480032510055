.navBarContainer {
    justify-content: space-between;
    background: linear-gradient(to right, #2E8BFF, #3A9FFC, #66B5FF);
    color: #3A9FFC;
    font-family: "Roboto";
    text-align: center;
    display: flex;
    align-items: center;
    height: 80px;
    position: absolute;
    width: 100%;
    top:0;
    z-index:2000;
}

.navBarChild {
    font-size: 20px;
    white-space: nowrap;
    margin-right: 3%;
    text-decoration: none;
    color:white;
    margin-left: 3%;
}

.navBarChild h3 {
    margin: 0;
}

.writeReviewButton {
    border-radius: 50%;
}

.searchBar {
    border-radius: 50px;
    width: 100%;
    margin: 0 auto;
    height: 30px;
    font-size: 20px;
    border: white solid 0.5px;
    text-indent: 30px;
    position: relative;
    font-family: "Roboto";
    margin-right: 10px;
    color: #A2ADB9;
}

.searchBarWithResults {
    border-radius: 10px 10px 0px 0px;
    width: 100%;
    margin: 0 auto;
    height: 30px;
    font-size: 20px;
    border: white solid 0.5px;
    text-indent: 30px;
    position: relative;
    font-family: "Roboto";
    color: #A2ADB9;
}

.searchBar:focus {
    outline: none;
}

.searchBarWithResults:focus {
    outline: none;
}

.searchContainer {
    position: relative;
    width: 100%;
    min-width: 120px;
}

.searchIcon {
    height: 30px;
    position: absolute;
    top: 50%;
    left: 6px;
    transform: translateY(-50%);
    color: #A2ADB9;
}

.navBarChild:hover {
    cursor: pointer;
}

.resultsContainer {
    text-align: left;
    width: calc(100% + 3px);
    position: absolute;
    background-color: white;
    border: 1px solid white;
    z-index: 1;
    border-radius: 0px 0px 10px 10px;
    max-height: 240px;
    overflow-y: scroll;
}

.resultsContainerNoResults {
    border: none;
}

.searchResult {
    color: #A2ADB9;
    font-family: "Roboto";
    font-weight: 600;
    padding-left: 16px;
}

.searchResult:last-child {
    border-bottom: none;
}

.searchResult p {
    margin:0;
    margin-left: 1em;
    padding-top:8px;
    padding-bottom:8px;
}

.containedText {
    font-size: 20px;
    cursor: pointer;
    display: inline-block;
    white-space: nowrap;
}

.containedText:hover {
    transition: 0.3s ease;
}

.searchResult:hover,
.searchResult.selected {
    background-color: #3A9FFC;
    color: white;
    cursor:pointer;
    transition: background-color 0.3s ease;
}

.buttonGroup {
    margin-left: 10px;
}

.close:hover {
    color: rgb(233, 122, 122);
    transition: color 0.5s ease;
}

.searchBar::placeholder {
    color: #A2ADB9;
}

.searchBarWithResults::placeholder {
    color: #A2ADB9;
}