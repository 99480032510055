.homeSearchContainer {
    position: relative;
    width: 80%;
    margin: 20px auto;
    margin-top: 14%;
    min-width: 840px;
}

@media (max-width: 1200px) {
    .homeSearchContainer {
        margin-top: 13%;
    }
  }

.homeSearchBarContainer {
    position: relative;
    width: 100%;
}

.homeSearchBar,
.homeSearchBarWithResults {
    border-radius: 20px;
    width: 100%;
    height: 100px;
    font-size: 48px; 
    border: 2px solid transparent;
    text-indent: 84px;
    color: #A2ADB9;
    background-color: white;
    background-clip: padding-box;
    box-shadow: 0 4px 6px rgba(58, 159, 252, 0.2); 
    transition: border 0.3s ease;
    position: relative;
}

.homeSearchBar:focus,
.homeSearchBarWithResults:focus {
    outline: none;
    border-width: 2px;
    box-shadow: 0 4px 6px rgba(58, 159, 252, 0.4);
}

.homeResultsContainer {
    position: absolute;
    width: calc(100% + 8px);
    background-color: white;
    border-radius: 0 0 20px 20px;
    max-height: 240px;
    overflow-y: auto;
    z-index: 10;
    box-shadow: 0 4px 6px rgba(58, 159, 252, 0.3);
    font-size: 32px;
    margin-top: -18px;
}

.homeResultsContainerNoResults {
    display: none;
}

.searchPlaceholder {
    position: absolute;
    top: 50%;
    left: 84px;
    transform: translateY(-50%);
    font-size: 48px;
    color: #A2ADB9;
    pointer-events: none;
    white-space: nowrap;
    transition: opacity 1s ease-in-out;
}

.fadeIn {
    opacity: 1;
}

.fadeOut {
    opacity: 0;
}

.searchIcon2 {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    color: #A2ADB9;
    font-size: 48px !important;
    pointer-events: none;
    z-index: 1;
}

.homeSearchBar::placeholder {
    color: #A2ADB9;
}